import { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
  faLineChart,
  faHourglass,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SecondPhoto from "../img/secondPhoto.jpg";
const threeCards = ["md:px-[10%]", "text-center", "font-[500]"].join(" ");
const threeCardsHeadline = ["px-[2%]", "text-center", "font-bold"].join(" ");

function InfoCard125({ cardData125 }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = useRef(null);
  return (
    <div className=" pb-12 drop-shadow-2xl border-0 bg-[rgb(255,255,255,0.2)]">
      <div className="pt-8">
        <img
          className="bg-animation hidden md:block p-0 bg-cover bg-center absolute z-0 w-full max-w-screen xl:max-w-[1400px] lg:pt-0"
          src={SecondPhoto}
          alt="F"
        />
      </div>
      <Slider {...settings} ref={slider}>
        {cardData125?.map(
          (
            item, id //TODO: map nebo foreach? Opravit, že to nic neháže, popř jenom jeden map a zjistit jak k každé iteraci příčíst +3 po jedné iteraci a jak k každému objektu přidat klíč+1 viz. item.description[0] a pak item.description[0+1]
          ) => (
            <div key={id} className="rounded-[50px] drop-shadow-[0_35px_35px_rgba(255,255,255,0.25)]">
              <FontAwesomeIcon
                id="help"
                className="fa-2x text-[#cd9f2d] absolute top-[60%] left-0 transform translate-y-[-50%] cursor-pointer"
                onClick={() => slider?.current?.slickPrev()}
                icon={faChevronLeft}
              />
              <div className="text-center">
                <div class="flex pb-4 pt-16 items-center justify-center w-[60%] mx-auto">
                  <div class="flex-grow border-t border-yellow-500"></div>
                  <span class="flex-shrink mx-4 text-black font-bold">
                    JAK ZAČÍT
                  </span>
                  <div class="flex-grow border-t border-yellow-500"></div>
                </div>
              </div>
              <div className="lg:w-[60%] w-[80%]  mx-auto p-2">
                <div className={`${threeCards}`}>
                  <h1 className="text-yellow-500 lg:text-[6vw] text-[500%] font-bold ">
                    {item.id + "."}
                  </h1>
                  <h2 className={`${threeCardsHeadline} `}>{item.title}</h2>
                  <span className="h-[20vw]"> {item.description}</span>
                </div>
              </div>
              <FontAwesomeIcon
                className="fa-2x text-[#cd9f2d] absolute top-[60%] right-0 transform translate-y-[-50%] cursor-pointer"
                onClick={() => slider?.current?.slickNext()}
                icon={faChevronRight}
              />
            </div>
          )
        )}
      </Slider>
    </div>
  );
}

export default InfoCard125;
