import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import SmallLogo from "../img/logoSmall.svg";

const mainWords = ["m-auto", "text-center", "font-medium", "lg:px-4", "px-2", "text-white","py-3","lg:py-0"].join(" ");
const iconsSocials = ["px-4", "text-white"].join(" ");
function Footer() {
    return (
        <div className="bg-[rgb(255,255,255,0.6)]">
            <div className="bg-[#fff4e4]" id="zluta-cast">
                <div class="flex pt-8 pb-2 items-center justify-center lg:w-[50%] mx-auto">
                    <div class="flex-grow border-t border-yellow-500"></div>
                    <span class="flex-shrink mx-4 text-black font-bold">MÁTE ZÁJEM O ZASÍLÁNÍ <br className="lg:hidden" /> NOVINEK A INFORMACÍ?</span>
                    <div class="flex-grow border-t border-yellow-500"></div>
                </div>
                <div className="text-center py-4 lg:py-8 max-w-[88%] mx-auto">
                    Zasíláme novinky a informace z oblasti investování do zlata, stříbra a investičních kovů.
                </div>
            </div>
            <div id="zlutejsi-cast-infolinka" className="text-center lg:flex items-center justify-center bg-[#cd9f2d] text-white">
                <div className="px-4 pt-4 pb-2">
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-white" />
                    <span className="mr-6 font-bold">info@zlatarenta.cz</span>
                </div>
                <div className="px-4 py-2">
                    <FontAwesomeIcon icon={faUser} className="mr-2 text-white" />
                    <span className="mr-6 font-bold"> Zákaznická linka +420 232 003 058 <br className="lg:hidden" /> PO–PÁ 8.00–17.00 HODIN </span>
                </div>
            </div>
            <div className="bg-[#393939]" id="šedy-logo">
                <img src={SmallLogo} className="lg:w-[36%] w-full max-w-[400px] m-auto p-8" />
                <div className="lg:grid grid-cols-2 lg:divide-x divide-gray-300">
                    <div className="flex flex-col lg:p-4">
                        <span className={`${mainWords}`}>
                            <span className="text-[#cd9f2d] font-bold ">UŽITEČNÉ INFORMACE</span>
                            <div className="lg:text-lg text-sm">
                                <br />
                                Ochrana osobních údajů
                                <br />
                                Ceník
                                <br />
                                Všeobecné obchodní podmínky
                                <br />
                                Časté dotazy
                                <br />
                                Podmínky marketingové akce
                            </div>

                        </span>
                    </div>
                    <div className="flex flex-col lg:p-4">
                        <span className={`${mainWords}`}>
                            <span className="text-[#cd9f2d] font-bold">KONTAKT</span>
                            <div className="lg:text-lg text-sm">
                                <br />
                                ZLATÁ RENTA, a.s.
                                <br />
                                Michalská 432/12
                                <br />
                                110 00 Praha - Staré město
                                <br />
                                IČ: 08345163
                                <br />
                                DIČ: CZ08345163
                            </div>
                        </span>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center p-8">
                    <FontAwesomeIcon icon={faUser} className={`${iconsSocials}`} />
                    <FontAwesomeIcon icon={faUser} className={`${iconsSocials}`} />
                    <FontAwesomeIcon icon={faUser} className={`${iconsSocials}`} />
                    <FontAwesomeIcon icon={faUser} className={`${iconsSocials}`} />
                    <FontAwesomeIcon icon={faUser} className={`${iconsSocials}`} />
                </div>
                <div class="relative flex py-5 items-center pt-8 pb-4">
                    <div class="flex-grow border-t border-[#cd9f2d]"></div>
                    <div class="flex-grow border-t border-[#cd9f2d]"></div>
                </div>
                <div className="text-xs text-center text-gray-400 mx-6">
                    Obsah tohoto webu nelze v žádném případě brát jako investiční doporučení. Uveřejněné články a analýzy je třeba vnímat jako subjektivní názory zaměstnanců společnosti SAFE HERITAGE, a.s.
                    Zveřejnění jakýchkoliv údajů či informací na Stránkách nemá povahu právního jednání směřujícího ke vzniku právního vztahu mezi vámi a námi. Publikování nebo další šíření obsahu webu zlataky.cz je bez předchozího písemného souhlasu společnosti SAFE HERITAGE,
                    a.s. zakázáno. Copyright © 2022 SAFE HERITAGE, a.s. | Vaše IP adresa je: 145.224.105.179
                </div>
            </div>


        </div>

    );
}

export default Footer;
