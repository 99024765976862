import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import NavbarMobile from "./navbarMobile";
import LongLogo from "../img/logoLong.svg";

const mainWords = ["m-auto", "text-center", "font-medium", "px-4"].join(" ");
function Navbar() {
  return (
    <div className="bg-[rgb(255,255,255,0.6)] ">
      <div className="flex justify-between items-center">
        <span className="mr-6 visible lg:hidden ml-4">
          <NavbarMobile />
        </span>
        <img src={LongLogo} className="w-[56vw] max-w-[320px] py-4 ml-6" />
        <div className="lg:flex justify-center items-center hidden lg:visible">
          <span className={`${mainWords} border-r border-[#cd9f2d]`}>ÚVOD</span>
          <span className={`${mainWords} border-r border-[#cd9f2d]`}>
            VŠE O ZLATĚ
          </span>
          <span className={`${mainWords} border-r border-[#cd9f2d]`}>
            VÝBĚR ZLATA
          </span>
          <span className={mainWords}>KONTAKTY</span>
        </div>
        <div className="flex">
          <FontAwesomeIcon
            icon={faUser}
            className="mr-2 text-[#cd9f2d] hidden lg:block mt-1"
          />
          <span className="mr-6 hidden lg:block">přihlasit</span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
