import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../App.css";
import AdminLogin from "../components/adminLogin.jsx";
import SidebarBackend from "../components/sidebarbackend.jsx";


function Admin(name, password, isLoggedIn, isAdmin) {
  return (
    <div className="">
      <SidebarBackend />

      <AdminLogin />
    </div>
  );
}

export default Admin;
