import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../App.css";
import Calcom from "../components/calccom.jsx";
import AdminLogin from "../components/adminLogin.jsx";
import AdminDataGrid from "../components/adminDataGrid";
import ReviewDataCard from "../components/reviewDataCard";
import CalcProductDisplay from "../components/calcProductDisplay";
import { useState } from "react";
import ContactUs from './../components/contactUs';

function Home() {
  const [dataSavingsMoney, setDataSavingsMoney] = useState();
  const [dataSavingsYears, setDataSavingsYears] = useState();

  const handleDataSavingsChangeMoney = (data) => {
    setDataSavingsMoney(data);
  };

  const handleDataSavingsChangeYears = (data) => {
    setDataSavingsYears(data);
  };

  return (
    <div className="App">
      {/* <ContactUs /> */}
      <div className="p-4">
        <Calcom
          passSavingsDataMoney={handleDataSavingsChangeMoney}
          passSavingsDataYears={handleDataSavingsChangeYears}
        />
      </div>
      <div className="p-4">
        <h2 className="font-bold m-4 pt-4 text-center">
          VYBERTE SI PRODUKT KE SPOŘENÍ
        </h2>
        <CalcProductDisplay
          receiveSavingsDataMoney={dataSavingsMoney}
          receiveSavingsDataYears={dataSavingsYears}
        />
      </div>
      <AdminDataGrid />
      <div className="grid grid-cols-4 pb-64">
        <ReviewDataCard />
        <ReviewDataCard />
        <ReviewDataCard />
        <ReviewDataCard />
      </div>
    </div>
  );
}

export default Home;
