import { useState, useRef } from "react";
import "../App.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faLineChart, faHourglass, faDumbbell, faAward, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const threeCards = ["md:px-[10%]", "text-center", "font-[500]", "mx-4"].join(" ");
const threeCardsHeadline = ["px-[2%]", "text-center", "font-bold", "text-sm", "lg:text-lg"].join(" ");
const threeCardsIcon = ["text-yellow-500", "fa-2x"].join(" ");

function InfoCard2x2Mobile({ cardData2x2 }) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const slider = useRef(null);
  return (
    <div className="lg:p-[10%] drop-shadow-2xl  border-0">
      <Slider {...settings} ref={slider}>
        {cardData2x2?.map((itemArray, id) => (
          <div key={id} className="relative">  {/* relative positioning added here */}
            <FontAwesomeIcon
              id="help"
              className="fa-2x text-[#cd9f2d] absolute top-[64%] left-0 transform translate-y-[-50%]"
              onClick={() => slider?.current?.slickPrev()}
              icon={faChevronLeft}
            />
            <div className="text-center">
              <div class="flex pb-4 pt-16 items-center justify-center w-[50%] mx-auto">
                <div class="flex-grow border-t border-yellow-500"></div>
                <span class="flex-shrink mx-4 text-black font-bold">PROČ SPOŘIT S NÁMI?</span>
                <div class="flex-grow border-t border-yellow-500"></div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 divide-x p-4 divide-gray-300 ">
              {itemArray?.map((item, id) => (
                <div key={id} className={`${threeCards}`}>
                  <FontAwesomeIcon icon={item.icon} className={`${threeCardsIcon} `} />
                  <h2 className={`${threeCardsHeadline} `}>{item.title}</h2>
                  {item.description}
                </div>
              ))}
            </div>
            <FontAwesomeIcon
              className="fa-2x text-[#cd9f2d] absolute top-[64%] right-0 transform translate-y-[-50%]"
              onClick={() => slider?.current?.slickNext()}
              icon={faChevronRight}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}


export default InfoCard2x2Mobile;
