import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../App.css";
import Calcom from "../components/calccom.jsx";

function Calc() {
  return (
    <div className="App">
      <Calcom />
    </div>
  );
}

export default Calc;
