import "../App.css";
import { useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync, faLineChart, faHourglass, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const threeCards = ["md:px-[10%]", "text-center", "font-[500]", "mx-4"].join(" ");
const threeCardsHeadline = ["px-[2%]", "text-center", "font-bold", "text-sm", "lg:text-lg"].join(" ");
const threeCardsIcon = ["text-yellow-500", "fa-2x"].join(" ");

function InfoCardCopyMobile({ cardData }) {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        border: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const slider = useRef(null);
    return (
        <div className="lg:p-4 drop-shadow-2xl border-0 pt-4 lg:pb-0">
            <Slider {...settings} ref={slider}>
                {cardData?.map((itemArray, id) => ( //TODO: map nebo foreach? Opravit, že to nic neháže, popř jenom jeden map a zjistit jak k každé iteraci příčíst +3 po jedné iteraci a jak k každému objektu přidat klíč+1 viz. item.description[0] a pak item.description[0+1]
                    <div key={id} className="border-0 rounded-[50px] bg-white drop-shadow-[0_35px_35px_rgba(255,255,255,0.25)] ">
                        <div className="text-center">
                            <div class="flex pt-8 lg:pt-16 items-center justify-center w-[90%] lg:w-[50%] mx-auto">
                                <div class="flex-grow border-t border-yellow-500"></div>
                                <span class="flex-shrink mx-4 text-black font-bold text-[90%] lg:text-[100%]">PROČ ZVOLIT ZLATOU RENTU?</span>
                                <div class="flex-grow border-t border-yellow-500"></div>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 divide-x p-4 divide-gray-300 ">
                            {itemArray.map((item, id) => (
                                <div  key={id} className={`${threeCards}`}>
                                    <FontAwesomeIcon
                                        id="help"
                                        className="fa-2x text-[#cd9f2d] absolute top-[50%] left-0 transform translate-y-[-50%]"
                                        onClick={() => slider?.current?.slickPrev()}
                                        icon={faChevronLeft}
                                    />
                                    <FontAwesomeIcon icon={item.icon} className={`${threeCardsIcon} `} />
                                    <h2 className={`${threeCardsHeadline} `}>{item.title}</h2>
                                    {item.description}
                                    <FontAwesomeIcon
                                        className="fa-2x text-[#cd9f2d] absolute top-[50%] right-0 transform translate-y-[-50%]"
                                        onClick={() => slider?.current?.slickNext()}
                                        icon={faChevronRight}
                                    />
                                </div>

                            ))}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}


export default InfoCardCopyMobile;
