import Home from "./pages/home";
import Admin from "./pages/loginAdmin";
import Calc from "./pages/calc";
import ContractForm from "./pages/contractForm";
import WebsiteTesting from "./pages/websiteTesting";

export const RoutesApp = [
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/kalkulacka",
    element: <Calc />,
  },
  {
    path: "/contractForm",
    element: <ContractForm />,
  },
  {
    path: "/",
    element: <WebsiteTesting />,
  },

];

export const RoutesNav = [
  {
    path: "/",
    name: "Hlavní Stránka",
    element: <WebsiteTesting />,
  },
];
