import "../App.css";
import { useState, useCallback, useEffect } from "react";
import { debounce } from "@mui/material/utils";
import { createTheme } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { ThemeProvider } from "@emotion/react";
import axios from "axios";
import { Link } from "react-router-dom";
import ModalForProduct from "../components/modalForProduct";

function CalcProductDisplay({
  receiveSavingsDataMoney,
  receiveSavingsDataYears,
}) {
  const baseURL = "https://api.zlatarenta.cz";
  const [postProduct, setPostProduct] = useState([]);
  const [postLevel, setPostLevel] = useState([]);
  const [savedUpMoney, setSavedUpMoney] = useState();
  const [productCode, setProductCode] = useState([]);
  const [actualProduct, setActualProduct] = useState([]);
  const [productPerSavingsMoney, setProductPerSavingsMoney] = useState(0);

  const [loaded, setLoaded] = useState(0);

  const compareProductCode = () => {
    const { levels } = postLevel;
    let pom = [];
    postProduct.forEach((itemProduct) => {
      productCode.forEach((item) => {
        if (itemProduct.code === item) {
          pom.push(itemProduct);
        }
      });
    });
    console.log(pom);
    setActualProduct(pom);
  };
  // prvni nacteni
  useEffect(() => {
    async function preloadData() {
      axios.get(`${baseURL}/v1/levels`).then((response) => {
        setPostLevel(response.data.data);
      });

      axios.get(`${baseURL}/v1/products`).then((response) => {
        setPostProduct(response.data.data);
        //tady delat filtraci
      });
    }

    if (loaded === 0) {
      preloadData();
      setLoaded(1);
    }
  }, []);

  //FIXME: Když skočím na Začit spořit a vrátím se, tak tam nezůstanou stejná data, ale data se bohužel resetujou
  const rangeSwitch = (value) => {
    const { levels } = postLevel;
    levels.forEach((item) => {
      if (item.range[0] <= value && item.range[1] >= value) {
        setProductCode(item.product_code);
      }
    });
  };

  useEffect(() => {
    const Formula =
      (receiveSavingsDataMoney + 99) * receiveSavingsDataYears * 12;
    setSavedUpMoney(Formula);
  }, [receiveSavingsDataMoney, receiveSavingsDataYears, savedUpMoney]);

  useEffect(() => {
    const { levels } = postLevel;
    if (levels !== undefined) {
      rangeSwitch(savedUpMoney);
    }
    compareProductCode();
  }, [postLevel, savedUpMoney]);

  const productPerSavings = (countOfProducts) => {
    const price = Math.floor(savedUpMoney / countOfProducts.price);
    return price;
  };
  const displayedProducts = ["p-4", "w-[36vw]", "m-auto"].join(" ");
  return (

    <div className="m-auto bg-white rounded-[100px] border-0 text-center md:grid grid-cols-2 grid-rows-2 lg:divide-x p-4 divide-gray-300 max-w-[1200px]">
      {actualProduct.length > 0 &&
        actualProduct.map((item) => (
          <div
            className={
              productPerSavings(item) === 0
                ? "hidden"
                : "lg:p-4"
            }
          >
            <div className="m-auto border-0 bq-gray-300 lg:w-[36vw] my-4">
              <div class="grid grid-cols-2 gap-2 text-start 2xl:max-w-[65%]">
                <div class="col-start-2 text-xl font-bold ">
                  <div className="rounded-full bg-[#cd9f2d] w-[26%] text-sm text-center text-white font-bold ">
                    {productPerSavings(item) + " ks"}
                  </div>
                  <div className="underline text-sm lg:max-w-[80%]">{`${item?.name}`}</div>
                  <div class="col-start-2 font-medium text-sm text-start ">
                    Měsíční Splátka
                  </div>
                  <div class="col-start-2 text-[#68b42c] font-bold lg:text-3xl text-2xl">
                    {`${(
                      productPerSavings(item) *
                      (item?.price / (receiveSavingsDataYears * 12))
                    )
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Kč`}
                    <span className="text-black text-sm font-normal">{" x" + receiveSavingsDataYears * 12}</span>
                  </div>
                  <button
                    linkto="/contractForm"
                    class="col-start-2 bg-[#68b42c] rounded-[100px] text-white text-sm font-bold w-[90%] h-[50px] mt-2 lg:w-[70%] lg:h-[50px] lg:mt-4 lg:rounded-[100px] lg:text-lg"
                  >
                    <Link to="/contractForm" className="text-white">
                      UZAVŘÍT SPOŘENÍ
                    </Link>
                  </button>
                  <div className="col-start-2 text-black  text-xs font-normal pt-2">
                    {`Aktivační Poplatek `}
                    {`${(item?.price / 100) * 6 < 2500
                      ? " 2500".toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      : (item?.price / 100) * 6 > 5000
                        ? " 5000".toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : ((item?.price / 100) * 6)
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                      } Kč`}
                  </div>
                </div>
                <img
                  className="col-start-1 row-start-1 row-end-2 w-full lg:w-[80%] m-auto drop-shadow-[0_35px_35px_rgba(205, 159, 45,1)] duration-300 hover:scale-[110%]"
                  src={`${item?.image}`}
                />
                {/* <div class="col-start-2   ">
                  <ModalForProduct item={item} />
                </div> */}
                {/* //FIXME: Nejde maximum s formatem*/}




              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default CalcProductDisplay;
