import "../App.css";
import InfoCard from "../components/infoCard";
import LandingPageInfo from "../components/landingPageInfo";
import ReviewDataCard from "./../components/reviewDataCard";
import Calcom from "../components/calccom.jsx";
import CalcProductDisplay from "../components/calcProductDisplay";
import { useState, useEffect } from "react";
import {
  faSync,
  faLineChart,
  faHourglass,
  faChevronRight,
  faChevronLeft,
  faSackDollar,
  faGift,
  faSliders,
  faDumbbell,
} from "@fortawesome/free-solid-svg-icons";
import InfoCard125 from "../components/infoCard125";
import InfoCard2x2 from "../components/infoCard2x2";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import PhotoFirst from "../img/photoFirst.jpg";
import MainPhoto from "../img/mainPhoto.jpg";
import SecondPhoto from "../img/secondPhoto.jpg";
import InfoCardCopy from "./../components/InfoCardCopy";
import ContactUs from "../components/contactUs";
import "animate.css";
import NavbarTop from "../components/navbarTop";
import InfoCardMobile from "../components/infoCardMobile";
import InfoCardCopyMobile from "../components/infoCardCopyMobile";
import InfoCard2x2Mobile from "./../components/infoCard2x2Mobile";

function WebsiteTesting() {
  const [dataSavingsMoney, setDataSavingsMoney] = useState();
  const [dataSavingsYears, setDataSavingsYears] = useState();

  const handleDataSavingsChangeMoney = (data) => {
    setDataSavingsMoney(data);
  };

  const handleDataSavingsChangeYears = (data) => {
    setDataSavingsYears(data);
  };

  const cardData1 = [
    [
      {
        id: 1,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
      {
        id: 2,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "Historicky si zlato udržuje svou hodnotu mnohem lépe než ničím nekryté papírové peníze, které podléhají inflaci. Od poslední krize je zlato ještě silněji vnímáno jako bezpečný přístav, v němž se vyplatí kotvit v době, kdy papírové měny ztrácejí na hodnotě.",
        icon: faLineChart,
      },
      {
        id: 3,
        title: "SMĚNITELNÉ VŽDY A VŠUDE",
        description:
          "Tradice obchodování se zlatem je stejně stará jako důvěra lidstva v jeho hodnotu. Lidé všech kultur pokládají zlato za cenný kov. Díky tomu je zlato univerzálním platidlem na každém kontinentě. Všude na světě najdete obchodníky, kteří ho od vás vykoupí.",
        icon: faSync,
      },
    ],
    [
      {
        id: 4,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
      {
        id: 5,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "Historicky si zlato udržuje svou hodnotu mnohem lépe než ničím nekryté papírové peníze, které podléhají inflaci. Od poslední krize je zlato ještě silněji vnímáno jako bezpečný přístav, v němž se vyplatí kotvit v době, kdy papírové měny ztrácejí na hodnotě.",
        icon: faLineChart,
      },
      {
        id: 6,
        title: "SMĚNITELNÉ VŽDY A VŠUDE",
        description:
          "Tradice obchodování se zlatem je stejně stará jako důvěra lidstva v jeho hodnotu. Lidé všech kultur pokládají zlato za cenný kov. Díky tomu je zlato univerzálním platidlem na každém kontinentě. Všude na světě najdete obchodníky, kteří ho od vás vykoupí.",
        icon: faSync,
      },
    ],
  ];

  const cardData1Mobile = [
    [
      {
        id: 1,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
    ],
    [
      {
        id: 2,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "Historicky si zlato udržuje svou hodnotu mnohem lépe než ničím nekryté papírové peníze, které podléhají inflaci. Od poslední krize je zlato ještě silněji vnímáno jako bezpečný přístav, v němž se vyplatí kotvit v době, kdy papírové měny ztrácejí na hodnotě.",
        icon: faLineChart,
      },
    ],
    [
      {
        id: 3,
        title: "SMĚNITELNÉ VŽDY A VŠUDE",
        description:
          "Tradice obchodování se zlatem je stejně stará jako důvěra lidstva v jeho hodnotu. Lidé všech kultur pokládají zlato za cenný kov. Díky tomu je zlato univerzálním platidlem na každém kontinentě. Všude na světě najdete obchodníky, kteří ho od vás vykoupí.",
        icon: faSync,
      },
    ],
  ];

  const cardData2 = [
    [
      {
        id: 1,
        title: "MÁTE NEUSTÁLÝ PŘEHLED",
        description:
          "Při dlouhodobém spoření nakupujete zlato za přijatelné ceny vycházející z dlouhodobého průměru. Nemusíte přitom kupovat celý slitek najednou. I při drobných měsíčních úložkách máte cenu za gram, jako byste nakupovali celý slitek.",
        icon: faSackDollar,
      },
      {
        id: 2,
        title: "K NIČEMU SE NEZAVAZUJETE",
        description:
          "Za spoření do zlata vás odměníme. Čím déle budete spořit se Zlatou rentou, tím více zlata od nás dostanete. Na uvítanou uložíme na váš účet zlato v hodnotě až 3 000 Kč. K tomu můžete získat bonus až 4 000 Kč za věrnost.",
        icon: faGift,
      },
      {
        id: 3,
        title: "U NÁS JSTE V BEZPEČÍ",
        description:
          "Zlatá renta se přizpůsobí vašim potřebám a možnostem. Sami si určíte, kolik peněz budete měsíčně investovat do zlata a jak dlouho chcete spořit. Svůj investiční program si nastavíte podle svých vlastních představ a cílů.",
        icon: faSliders,
      },
    ],
    [
      {
        id: 4,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "4Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
      {
        id: 5,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "5Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faLineChart,
      },
      {
        id: 6,
        title: "SMĚNITELNÉ VŽDY A VŠUDE",
        description:
          "6Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faSync,
      },
    ],
  ];

  const cardData2Mobile = [
    [
      {
        id: 1,
        title: "MÁTE NEUSTÁLÝ PŘEHLED",
        description:
          "Při dlouhodobém spoření nakupujete zlato za přijatelné ceny vycházející z dlouhodobého průměru. Nemusíte přitom kupovat celý slitek najednou. I při drobných měsíčních úložkách máte cenu za gram, jako byste nakupovali celý slitek.",
        icon: faSackDollar,
      },
    ],
    [
      {
        id: 2,
        title: "K NIČEMU SE NEZAVAZUJETE",
        description:
          "Za spoření do zlata vás odměníme. Čím déle budete spořit se Zlatou rentou, tím více zlata od nás dostanete. Na uvítanou uložíme na váš účet zlato v hodnotě až 3 000 Kč. K tomu můžete získat bonus až 4 000 Kč za věrnost.",
        icon: faGift,
      },
    ],
    [
      {
        id: 3,
        title: "U NÁS JSTE V BEZPEČÍ",
        description:
          "Zlatá renta se přizpůsobí vašim potřebám a možnostem. Sami si určíte, kolik peněz budete měsíčně investovat do zlata a jak dlouho chcete spořit. Svůj investiční program si nastavíte podle svých vlastních představ a cílů.",
        icon: faSliders,
      },
    ],
    [
      {
        id: 4,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "4Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
    ],
    [
      {
        id: 5,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "5Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faLineChart,
      },
    ],
    [
      {
        id: 6,
        title: "SMĚNITELNÉ VŽDY A VŠUDE",
        description:
          "6Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faSync,
      },
    ],
  ];

  const cardData125_1 = [
    {
      id: 1,
      title: "NASTAVTE SI ZLATOU RENTU NA MÍRU",
      description:
        "Během pár minut si v kalkulačce Zlaté renty nastavíte spoření podle svých představ. Sami si určíte výši měsíčního vkladu a dobu spoření. My vám spočítáme potenciál vašeho spoření a výši bonusu, jenž od nás získáte. Ostatní nechte na nás.",
      icon: faHourglass,
    },
    {
      id: 2,
      title: "ZALOŽÍME VÁM ÚČET",
      description:
        "V následujícím kroku vám založíme osobní účet, který si aktivujte zasláním aktivační platby. Od této chvíle budete moci své spoření se Zlatou rentou obsluhovat přes počítač nebo mobilní telefon.",
      icon: faLineChart,
    },
    {
      id: 3,
      title: "NAKUPUJTE A ZHODNOCUJTE",
      description:
        "Ve své bance si nastavíte trvalý příkaz, nebo nám každý měsíc pošlete částku, za kterou vám nakoupíme zlato. Cenu zlata transparentně uvádíme na tomto webu. Nákupy a zhodnocení kovu můžete sledovat na svém účtu Zlaté renty.",
      icon: faSync,
    },
    {
      id: 4,
      title: "VYBERTE SI ÚSPORY, KDYKOLIV POTŘEBUJETE",
      description:
        "Nečekané výdaje? Se Zlatou rentou žádný problém. Kdykoliv můžete celou zlatou investici nebo její část zpětně prodat. Finance vám obratem pošleme na bankovní účet. Po ukončení spoření si zlato u nás můžete uložit, nechat si jej zaslat domů či osobně vyzvednout.",
      icon: faHourglass,
    },
    {
      id: 5,
      title: "UŽÍVEJTE SI SVOU RENTU",
      description:
        "To, jak se svou Zlatou rentou naložíte, už je jen na vás. Můžete začít počítat. Bude to dům snů, důchod bez starostí, nebo něco jiného?",
      icon: faLineChart,
    },
  ];

  const cardData2x2_1 = [
    [
      {
        id: 1,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "Zlatá renta je projekt společnosti Zlaťáky. Od roku 2006, kdy jsme vstoupili na trh, jsme vyrostli v největšího retailového prodejce zlata v České republice. Máme zkušenosti i nejvýhodnější ceny na trhu v porovnání s největšími tuzemskými obchodníky.",
        icon: faHourglass,
      },
      {
        id: 2,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "Díky dlouhodobému působení v oborujsme si vybudovali vynikající vztahy sezavedenými distributory investičních mincía slitků. Spolupracujeme s těmi nejlepšími.Máme nejkratší možné distribuční cestya nabízíme zlato prémiových značek zavýhodné ceny.",
        icon: faHourglass,
      },
    ],
    [
      {
        id: 3,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "3Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
      {
        id: 4,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "4Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faLineChart,
      },
    ],
    [
      {
        id: 5,
        title: "DŮKLADNĚ PROVĚŘENO ČASEM",
        description:
          "5Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faHourglass,
      },
      {
        id: 6,
        title: "STABILNÍ UCHOVATEL HODNOTY",
        description:
          "6Důvěra ve zlato provází lidstvo od nepaměti. S tímto drahým kovem si lidé spojují prestiž, moc a bohatství a odjakživa jim slouží jako uchovatel hodnoty. Zlato přežilo ekonomické krize, války i rozličné měnové systémy a svou roli zastává dodnes.",
        icon: faLineChart,
      },
    ],
  ];

  const cardData2x2_1Mobile = [
    [
      {
        id: 1,
        title: "JSME SILNÝM PARTNEREM",
        description:
          "Zlatá renta je projekt společnosti Zlaťáky. Od roku 2006, kdy jsme vstoupili na trh, jsme vyrostli v největšího retailového prodejce zlata v České republice. Máme zkušenosti i nejvýhodnější ceny na trhu v porovnání s největšími tuzemskými obchodníky.",
        icon: faDumbbell,
      },
    ],
    [
      {
        id: 2,
        title: "GARANTUJEME KVALITU ZLATA",
        description:
          "Díky dlouhodobému působení v oboru jsme si vybudovali vynikající vztahy se zavedenými distributory investičních mincí a slitků. Spolupracujeme s těmi nejlepšími. Máme nejkratší možné distribuční cesty a nabízíme zlato prémiových značek za výhodné ceny.",
        icon: faSackDollar,
      },
    ],
  ];

  const reviewCardData1lg = [
    [
      {
        id: 21,
        name: "Jiří",
        review:
          "Bezproblémový nákupinvestičního zlata. Běhemvyřizování smlouvy měprůběžně informovali o stavu.Přehled nákupů ihned na mémúčtu. Doporučuji.",
      },
    ],
    [
      {
        id: 2,
        name: "Jiří",
        review:
          "Bezproblémový nákupinvestičního zlata. Běhemvyřizování smlouvy měprůběžně informovali o stavu.Přehled nákupů ihned na mémúčtu. Doporučuji.",
      },
    ],
    [
      {
        id: 3,
        name: "Jiří",
        review:
          "Bezproblémový nákupinvestičního zlata. Běhemvyřizování smlouvy měprůběžně informovali o stavu.Přehled nákupů ihned na mémúčtu. Doporučuji.",
      },
    ],
    [
      {
        id: 4,
        name: "Jiří",
        review:
          "Bezproblémový nákupinvestičního zlata. Běhemvyřizování smlouvy měprůběžně informovali o stavu.Přehled nákupů ihned na mémúčtu. Doporučuji.",
      },
    ],
  ];

  const reviewCardData1md = [
    [
      {
        id: 1,
        review:
          "Vše jsem si jednoduše nastavila v kalkulačce a vyřídila online. Jednodušší to už být nemohlo :)",
        name: "Jirka",
      },
      {
        id: 2,
        review:
          "Už dlouho jsem čekal na možnost pravidelného spoření od Zlaťáků. Super ceny!",
        name: "Honza",
      },
    ],
    [
      {
        id: 1,
        review:
          "Už dlouho jsem čekal na možnost pravidelného spoření od Zlaťáků. Super ceny!",
        name: "Jirka",
      },
      {
        id: 2,
        review:
          "Díky dlouhodobému působení v oborujsme si vybudovali vynikající vztahy sezavedenými distributory investičních mincía slitků. Spolupracujeme s těmi nejlepšími.Máme nejkratší možné distribuční cestya nabízíme zlato prémiových značek zavýhodné ceny.",
        name: "Honza",
      },
    ],
  ];
  const reviewCardData1mdCopy = [
    [
      {
        id: 1,
        review:
          "Vše jsem si jednoduše nastavila v kalkulačce a vyřídila online. Jednodušší to už být nemohlo :)",
        name: "Jirka",
      },
      {
        id: 2,
        review:
          "Už dlouho jsem čekal na možnost pravidelného spoření od Zlaťáků. Super ceny!",
        name: "Honza",
      },
    ],
    [
      {
        id: 1,
        review:
          "Už dlouho jsem čekal na možnost pravidelného spoření od Zlaťáků. Super ceny!",
        name: "Jirka",
      },
      {
        id: 2,
        review:
          "Už dlouho jsem čekal na možnost pravidelného spoření od Zlaťáků. Super ceny!",
        name: "Honza",
      },
    ],
  ];

  const LandingPageInfoText =
    "Vybudujte si i Vy, systematicky i po malých částkách, vlastní zlatý poklad. Finanční budoucnost z fyzických zlatých slitků bez daní jako                alternativa k inflačním měnám. Vsaďte na věky prověřenou jistotu, že ať už budoucnost přinese cokoliv, Váš zlatý poklad nikdy neselže.";

  const LandingPageInfoTextShort =
    "Vybudujte si i Vy, systematicky i po malých částkách, vlastní zlatý poklad.";

  return (
    <div className="max-w-[1400px] m-auto w-full h-full p-0 overflow-x-hidden bg-white">
      <div>
        <img
          className="bg-animation bg-cover bg-center absolute z-0  max-w-screen xl:max-w-[1400px] pt-32 sm:pt-16 lg:pt-0"
          src={MainPhoto}
          alt="F"
        />
      </div>

      <div className="z-20 relative">
        <div className="">
          <NavbarTop />
          <div>
            <Navbar />
          </div>
        </div>
        <div
          className="sm:pt-36 pt-24 pb-4 w-[100%] animated slideInDown"
          class="animate__animated animate__bounce animated"
        >
          <LandingPageInfo text={LandingPageInfoTextShort} />
          {/* padding a margin */}
        </div>

        <div className="lg:pt-32 pt-16 hidden lg:block">
          <InfoCard cardData={cardData1} />
          <InfoCardCopy cardData={cardData2} />
        </div>
        <div className="lg:pt-32 pt-16 block lg:hidden">
          <InfoCardMobile cardData={cardData1Mobile} />
          <InfoCardCopyMobile cardData={cardData2Mobile} />
        </div>
        <InfoCard125 cardData125={cardData125_1} />
      </div>
      <div className="z-20 relative bg-white max-w-[1200px] w-[90vw] h-[40%] xl:h-[30%] m-auto rounded-[70px] border-0 drop-shadow-[0_25px_25px_rgba(200,200,200,0.6)]">
        <div className="">
          <Calcom
            passSavingsDataMoney={handleDataSavingsChangeMoney}
            passSavingsDataYears={handleDataSavingsChangeYears}
          />
        </div>
        <div className="pb-4">
          <h2 className="font-bold m-4 text-center">
            VYBERTE SI PRODUKT KE SPOŘENÍ
          </h2>
          <CalcProductDisplay
            receiveSavingsDataMoney={dataSavingsMoney}
            receiveSavingsDataYears={dataSavingsYears}
          />
        </div>
      </div>
      <div className=" hidden lg:block">
        <InfoCard2x2 cardData2x2={cardData2x2_1} />
      </div>
      <div className=" block lg:hidden">
        <InfoCard2x2Mobile cardData2x2={cardData2x2_1Mobile} />
      </div>
      <div className="flex z-20 relative lg:px-8">
        <div>
          <ReviewDataCard
            className="lg:hidden"
            name={"Ondřej"}
            review={
              "Vše jsem si jednoduše nastavila v kalkulačce a vyřídila online. Jednodušší to už být nemohlo :) Díky"
            }
          />
        </div>
        <div>
          <ReviewDataCard
            className="lg:hidden"
            name={"Ondřej"}
            review={
              "Vše jsem si jednoduše nastavila v kalkulačce a vyřídila online. Jednodušší to už být nemohlo :) Díky"
            }
          />
        </div>
        <div className="hidden lg:block">
          <ReviewDataCard
            className="lg:hidden"
            name={"Ondřej"}
            review={
              "Vše jsem si jednoduše nastavila v kalkulačce a vyřídila online. Jednodušší to už být nemohlo :) Díky"
            }
          />
        </div>
        <div className="hidden lg:block">
          <ReviewDataCard
            className="lg:hidden"
            name={"Ondřej"}
            review={
              "Vše jsem si jednoduše nastavila v kalkulačce a vyřídila online. Jednodušší to už být nemohlo :) Díky"
            }
          />
        </div>
      </div>
      <div className="flex z-20 relative bottom-0">
        <Footer />
        <a href="https://zlataky.cz" target="_blank" rel="noopener noreferrer">
          {/* <ContactUs /> */}
        </a>

        {/* Zkusil jsem udělat/najít animaci a změnil jsem barvy */}
      </div>
    </div>
  );
}

export default WebsiteTesting;
