import { useState, useRef } from "react";
import { useIdleTimer } from "react-idle-timer";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";


const ReviewDataCard = ({ review, name, reviewCardData }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const slider = useRef(null);
  return (
    <div className="lg:p-8 px-3 py-8">
      <div className="w-full bg-[#dedee0] rounded-[38px] pb-6">
        <div className="w-[90%] my-4 lg:max-w-[300px] bg-[#dedee0] rounded-[38px] mx-2 lg:max-h-[450px] max-h-[270px] grid grid-cols-1">
          <img className="rounded-full lg:p-4 w-[60%] p-2 mt-4 ml-4  md:w-[60%]" src="https://media.licdn.com/dms/image/C4D03AQHIhq2EHTurvw/profile-displayphoto-shrink_200_200/0/1516631637989?e=1693440000&v=beta&t=1PSBZQWsrXlueVmFCIRcjBZGYcGTrQrYdXTRATIDwXU" />
          <div className="lg:p-4 mx-1 lg:mx-0 p-1 text-sm lg:text-lg">
            {review}
          </div>
          <div className="lg:p-4 mx-1 pl-2 mt-4 text-start  font-bold text-sm">
            {name}
          </div>
        </div>
      </div>
    </div >
  );
};

export default ReviewDataCard;
