import { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const localizedTextsMap = {
  columnMenuUnsort: "čeština",
  columnMenuSortAsc: "Classificar por ordem crescente",
  columnMenuSortDesc: "Classificar por ordem decrescente",
  columnMenuFilter: "Filtro",
  columnMenuHideColumn: "Ocultar",
  columnMenuShowColumns: "Mostrar colunas",
};

function AdminDataGrid() {
  const baseURL = "https://api.zlatarenta.cz";
  const [post, setPost] = useState([]);
  const [mappedData, setMappedData] = useState([]);

  const axiosCall = async () => {
    await axios.get(`${baseURL}/v1/products`).then((response) => {
      setPost(response.data.data);
    });
  };

  useEffect(() => {
    axiosCall();
  }, []);

  useEffect(() => {
    mapData();
  }, [post]);

  function mapData() {
    const preMappedData = post.map((item, id) => {
      return {
        id: id,
        name: item?.name,
        code: item?.code,
        type: item?.type,
        price: item?.price,
        weight: item?.weight,
        link: item?.link,
      };
    });
    setMappedData(preMappedData);
  }

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 10,
      headerClassName: "gold-color--header",
    },
    {
      field: "name",
      headerName: "name",
      width: 400,
      headerClassName: "gold-color--header",
    },
    {
      field: "code",
      headerName: "code",
      width: 100,
      headerClassName: "gold-color--header",
    },
    {
      field: "type",
      headerName: "type",
      width: 60,
      headerClassName: "gold-color--header",
    },
    {
      field: "price",
      headerName: "price",
      width: 80,
      headerClassName: "gray-color--header",
    },
    {
      field: "weight",
      headerName: "weight",
      width: 110,
      headerClassName: "gold-color--header",
    },
    {
      field: "link",
      headerName: "link",
      width: 650,
      headerClassName: "gray-color--header",
    },
  ];

  return (
    <div className="w-[100%] flex justify-center items-center pt-16">
      <div className="w-[50%]">
        <div style={{ height: 400, width: "100%" }}>
          <Box
            sx={{
              height: 300,
              width: "100%",
              "& .gold-color--header": {
                backgroundColor: "#cd9f2d",
              },
              "& .gray-color--header": {
                backgroundColor: "#d4d4d4",
              },
            }}
          >
            <DataGrid
              sx={{
                boxShadow: 2,
                border: 2,
                borderColor: "#cd9f2d",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
              }}
              rows={mappedData}
              columns={columns}
              localeText={localizedTextsMap}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default AdminDataGrid;
