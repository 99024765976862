import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import Calcom from "./components/calccom.jsx";
import AdminLogin from "./components/adminLogin.jsx";
import AdminDataGrid from "./components/adminDataGrid";
import { RoutesApp } from "./routes-nav.js";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";

function App() {
  return (
    <div className="">
      <BrowserRouter basename="/">
        <div className="min-h-[100vh] flex flex-col">
          {/* <Navbar /> */}
          <Routes>
            {RoutesApp.map((item, id) => (
              <Route key={id} exact path={item.path} element={item.element} />
            ))}
          </Routes>
          {/* <Footer /> */}
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
