import { useState } from "react";


function LandingPageInfo({text}) {
    return (
        <div className="text-center text-white p-2 mt-64 m-auto drop-shadow-[0_0_25px_rgba(0,0,0,1)]">
            <h1 className="text-[250%] lg:text-[400%] pt-8 w-full h-full drop-shadow-[0_0_25px_rgba(0,0,0,1)]">Budoucnost ve zlatě</h1>
            <p className=" max-w-[80%] md:max-w-[50%] m-auto pb-8 pt-2 text-[120%] lg:text-[200%] drop-shadow-[0_0_25px_rgba(0,0,0,1)]">{text}</p>
            <button className="bg-[#cd9f2d] rounded-full py-2 px-12 font-bold text-xl lg:text-2xl max-w-[80%] lg:max-w-[50%] hover:scale-110">CHCI ZAČÍT SPOŘIT</button>
        </div>
    );
}

export default LandingPageInfo;
