import ContractFormComp from "../components/contractFormComp";
import ModalForProduct from "../components/modalForProduct";
function ContractForm() {
  return (
    <div className="grid place-items-center">
      <ContractFormComp />
    </div>
  );
}

export default ContractForm;
