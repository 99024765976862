import { Drawer, Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DataArray from "@mui/icons-material/DataArray";
import PersonIcon from "@mui/icons-material/Person";
import GavelIcon from "@mui/icons-material/Gavel";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";

const NavbarMobile = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const sidebar = ["py-3", "text-left", "text-black", "pl-2"].join(" ");

    const divSidebar = [
        "hover:bg-[#cd9f2d]",
        "flex",
        "items-center",
        "pl-2",
        "rounded",
    ].join(" ");

    return (
        <div>
            <IconButton
                onClick={() => setIsDrawerOpen(true)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="logo"
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="top"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                PaperProps={{
                    sx: {
                        backgroundColor: "#FFF",
                        color: "#cd9f2d",
                        marginLeft: "25%",
                        marginRight: "25%",
                    },
                }}
            >
                <Box p={2} width="250px" role="presentation">
                    <p className="text-center font-bold text-lg">Zlaťáky</p>
                    <hr></hr>
                    <Link to="/kalkulacka">
                        <div className={`${divSidebar}`}>
                            <DataArray className="text-black" />
                            <Typography variant="h5" component="p" className={`${sidebar}`}>
                                ÚVOD
                            </Typography>
                        </div>
                    </Link>
                    <Link to="/kalkulacka">
                        <div className={`${divSidebar}`}>
                            <PersonIcon className="text-black" />
                            <Typography variant="h5" component="p" className={`${sidebar}`}>
                                VŠE O ZLATE
                            </Typography>
                        </div>
                    </Link>

                    <Link to="/kalkulacka">
                        <div className={`${divSidebar}`}>
                            <GavelIcon className="text-black" />
                            <Typography variant="h5" component="p" className={`${sidebar}`}>
                                VÝBĚR ZLATA
                            </Typography>
                        </div>
                    </Link>

                    <Link to="/kalkulacka">
                        <div className={`${divSidebar}`}>
                            <MonetizationOnIcon className="text-black" />
                            <Typography variant="h5" component="p" className={`${sidebar}`}>
                                KONTAKTY
                            </Typography>
                        </div>
                    </Link>


                </Box>
                <LogoutIcon className="text-black " />
            </Drawer>

        </div>
    );
};

export default NavbarMobile;
