import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import { TextField } from "@material-ui/core";

function ContractFormComp() {
  const dataArray = [
    "Jméno",
    "Příjmení",
    "Město",
    "Ulice",
    "PSČ",
    "Telefon",
    "Email",
  ];

  const theme = createTheme({
    palette: {
      zlataky: {
        main: "#cd9f2d",
        contrastText: "#fff",
      },
    },
  });

  return (
    <div className="text-center">
      <form>
        {dataArray.map((item) => (
          <>
            <TextField
              style={{ width: "800px", margin: "15px" }}
              type="text"
              label={`${item}`}
              variant="outlined"
            />
            <br />
          </>
        ))}
        <ThemeProvider theme={theme}>     
          <Button variant="contained" color="zlataky">
            Potvrdit a odeslat objednávku
          </Button>
        </ThemeProvider>
      </form>
    </div>
  );
}

export default ContractFormComp;
