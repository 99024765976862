import { Drawer, Box, Typography, IconButton } from "@mui/material";
import { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DataArray from "@mui/icons-material/DataArray";
import PersonIcon from "@mui/icons-material/Person";
import GavelIcon from "@mui/icons-material/Gavel";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from "react-router-dom";

const SidebarBackend = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const sidebar = ["py-3", "text-left", "text-white", "pl-2"].join(" ");

  const divSidebar = [
    "hover:bg-[#cd9f2d]",
    "flex",
    " items-center",
    "pl-2",
    "rounded",
  ].join(" ");

  return (
    <div>
      <IconButton
        onClick={() => setIsDrawerOpen(true)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="logo"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#1a1a1a",
            color: "#cd9f2d",
          },
        }}
      >
        <Box p={2} width="250px" role="presentation">
          <p className="text-center font-bold text-lg">Zlaťáky Adminstrace</p>
          <hr></hr>
          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <DataArray className="text-white" />
              <Typography variant="h5" component="p" className={`${sidebar}`}>
                Data
              </Typography>
            </div>
          </Link>
          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <PersonIcon className="text-white" />
              <Typography variant="h5" component="p" className={`${sidebar}`}>
                Zákaznici
              </Typography>
            </div>
          </Link>

          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <GavelIcon className="text-white" />
              <Typography variant="h5" component="p" className={`${sidebar}`}>
                Smlouvy
              </Typography>
            </div>
          </Link>

          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <MonetizationOnIcon className="text-white" />
              <Typography variant="h5" component="p" className={`${sidebar}`}>
                Produkty
              </Typography>
            </div>
          </Link>

          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <SettingsIcon className="text-white" />
              <Typography variant="h5" component="p" className={`${sidebar}`}>
                Nastavení
              </Typography>
            </div>
          </Link>

          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <BarChartIcon className="text-white" />
              <Typography variant="h5" component="p" className={`${sidebar}`}>
                Statistiky
              </Typography>
            </div>
          </Link>

          <Link to="/kalkulacka">
            <div className={`${divSidebar}`}>
              <DashboardIcon className="text-white" />
              <Typography
                variant="h5"
                component="p"
                className={`${sidebar}`}
                sx={{
                  "&:hover": {
                    background: "#cd9f2d",
                  },
                }}
              >
                Dashboard
              </Typography>
            </div>
          </Link>
        </Box>
          <LogoutIcon className="text-white " />
      </Drawer>
  
    </div>
  );
};

export default SidebarBackend;
