import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import NavbarMobile from "./navbarMobile";

const mainWords = ["m-auto", "text-center", "font-medium", "px-4"].join(" ");
function NavbarTop() {
  return (
    <div className="bg-[rgba(56,55,55,0.87)] h-[40px] lg:h-[60px] flex relative w-full">
      <div
        className="bg-[rgb(255,255,255,1)] hover:scale-105 hover:bg-[rgb(214,214,214)] rounded-tr-[30px] text-xs lg:text-lg  py-2 z-30 relative w-[150%] lg:w-[20%] text-center font-bold cursor-pointer"
        style={{ left: "0px", zIndex: "3" }}
      >
        <a href="https:///www.zlatarenta.cz" target="_blank" className="text-inherit hover:text-inherit">ZLATÁRENTA.CZ</a>
        <div className="text-xs font-normal hidden lg:block">
          SPOŘENÍ DO ZLATA
        </div>
      </div>
      <div
        className="bg-[rgb(205,159,45,1)] hover:scale-105 hover:bg-[rgb(144,112,31)] text-xs lg:text-lg py-2 rounded-tr-[30px] z-20 relative w-[150%] lg:w-[21%] text-center font-bold cursor-pointer"
        style={{ left: "-21px", zIndex: "2" }}
      >
        <a href="https:///www.zlataky.cz" target="_blank" className="text-inherit hover:text-inherit">ZLAŤÁKY.CZ</a>
        <div className="text-xs font-normal hidden lg:block">
          INVESTIČNÍ A SBĚRATELSKÉ ZLATO
        </div>
      </div>
      <div
        className="bg-[rgba(140,138,138,1)] hover:scale-105 hover:bg-[#767474] text-xs lg:text-lg  py-2 text-white rounded-tr-[30px] z-10 relative w-[150%] lg:w-[23%] text-center font-bold cursor-pointer"
        style={{ left: "-41px", zIndex: "1" }}
      >
        <a href="https:///www.zlataky.cz" target="_blank" className="text-inherit hover:text-inherit">STŘÍBRŇÁKY.CZ</a>
        <div className="text-xs font-normal hidden lg:block">
          INVESTIČNÍ A SBĚRATELSKÉ STŘÍBRO
        </div>{" "}
        {/* FIXME: Hidden z nějakého důvodu, nechce přejít do visible i když překročí hranici LG, nevím jestli to je opera bug, ale prostě to nejde. */}
      </div>
      <div className="text-center lg:flex items-center justify-center">
        <div className="lg:flex hidden lg:visible">
          <FontAwesomeIcon
            icon={faUser}
            className="mr-2 text-[rgb(205,159,45,1)]"
          />
          <span className="mr-2 font-bold text-white">info@zlatarenta.cz</span>
        </div>
        <div className="lg:flex hidden lg:visible">
          <FontAwesomeIcon
            icon={faUser}
            className="mr-2 text-[rgb(205,159,45,1)]"
          />
          <span className="font-bold text-white">
            {" "}
            Zákaznická linka +420 232 003 058{" "}
          </span>
        </div>
      </div>
    </div>
  );
}

export default NavbarTop;
