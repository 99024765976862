import "../App.css";
import { useState, useCallback, useEffect } from "react";
import { debounce } from "@mui/material/utils";
import { createTheme } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { ThemeProvider } from "@emotion/react";

function Calccom({ passSavingsDataMoney, passSavingsDataYears }) {
  const [value, setValue] = useState(1000);
  const [value2, setValue2] = useState(3);
  const [value3, setValue3] = useState(5);

  const handleSliderChange = useCallback((event, value) => {
    //Posílat tyto hodnoty do databáze po nějaké době
    debounceSliderChange(value);
  }, []);

  const handleSliderChange2 = useCallback((event, value2) => {
    debounceSliderChange2(value2);
  }, []);

  const debounceSliderChange = debounce((val) => {
    setValue(val);
    passSavingsDataMoney(val);
  }, 0);

  const debounceSliderChange2 = debounce((val) => {
    setValue2(val);
    passSavingsDataYears(val);
  }, 0);

  const resetValue = () => {
    handleSliderChange(null, 1000);
    handleSliderChange2(null, 5);
  };

  const resetValue2 = () => {
    handleSliderChange(null, 3000);
    handleSliderChange2(null, 3);
  };

  const resetValue3 = () => {
    handleSliderChange(null, 5000);
    handleSliderChange2(null, 2);
  };

  useEffect(() => {
    passSavingsDataMoney(value);
    passSavingsDataYears(value2);
  }, []); //Potencialene irelevantní

  //TODO: Tohle nebylo potřeba, ale neaktualizovali se produkty, při kliku na reset button
  useEffect(() => {
    passSavingsDataMoney(value);
    passSavingsDataYears(value2);
  }, [value, value2]);

  const { palette } = createTheme();
  const theme = createTheme({
    palette: {
      myAwesomeColor: palette.augmentColor({
        color: {
          main: "#cd9f2d",
        },
      }),
    },
  });

  const total = Math.pow(1 + value3 / 100, value2) * value * (12 * value2); //total
  const displayValue = Math.round(total).toLocaleString() + " Kč"; //total hezky
  const totalBeforeIncrease = (value * (12 * value2)).toLocaleString() + " Kč"; //total bez nárůstu
  const moneyWithSubscription =
    ((value + 99) * value2 * 12).toLocaleString() + " Kč"; //total + poplatek
  const profit = (total - value * (12 * value2)).toLocaleString() + " Kč"; //profit

  const twoSlidersInnerDiv = ["lg:p-4", "lg:w-[36vw]", "m-auto", "w-full"].join(
    " "
  );

  return (
    <div className="text-center m-auto w-[90vw] h-[40%] xl:h-[30%] bg-[#ffffff] rounded-[70px] border-0 px-4 lg:px-16 pb-16 max-w-[1200px] drop-shadow-[0_0_35px_rgba(200,200,200,0.8)]">
      <ThemeProvider theme={theme}>
        <div className="font-bold">
          <div class="flex items-center justify-center lg:w-[50%] mx-auto pt-4">
            <div class="flex-grow border-t border-[#cd9f2d] "></div>
            <span class="flex-shrink mx-2 text-black font-bold">
              KALKULAČKA ZLATÉ RENTY
            </span>
            <div class="flex-grow border-t border-[#cd9f2d]"></div>
          </div>
          <h2 className=" text-black font-bold">NEJOBLÍBENĚJŠÍ VARIANTY SPOŘENÍ</h2>
          <div className="flex justify-center items-center pt-4">
            <button
              onClick={resetValue} //TODO: Koukni na mě
              type="button"
              className="bg-[#cd9f2d] text-white rounded-2xl text-center h-[36px] w-[48%] text-sm lg:w-[20%] mx:2 lg:h-[40px] mb-5 lg:mx-3 drop-shadow-[6px_4px_0.1px_rgba(104,180,44,1)] hover:drop-shadow-[0_5px_35px_rgba(104,180,44,1)] transition duration-300 ease-in-out hover:scale-105"
            >
              1000 KČ NA 5 LET
            </button>
            <button
              onClick={resetValue2} //TODO: Koukni na mě
              type="button"
              className="bg-[#cd9f2d]  text-white rounded-2xl text-center h-[36px] w-[48%] text-sm lg:w-[20%] mx-2 px-1 lg:h-[40px] mb-5 lg:mx-3 drop-shadow-[6px_4px_0.1px_rgba(120,120,120,1)] hover:drop-shadow-[0_5px_35px_rgba(120,120,120,1)] transition duration-300 ease-in-out hover:scale-105"
            >
              3000 KČ NA 3 ROKY
            </button>
            {/* <button
              onClick={resetValue3} //TODO: Koukni na mě
              type="button"
              className="bg-[#cd9f2d] text-white rounded-2xl text-center w-[20%] h-[40px] mb-5 mx-3 drop-shadow-[6px_4px_0.1px_rgba(205,159,45,1)] hover:drop-shadow-[0_5px_35px_rgba(205,159,45,1)] transition duration-300 ease-in-out hover:scale-125"
            >
              5000 KČ NA 2 ROKY
            </button> */}
          </div>
          <div className="lg:flex">
            <div className={`${twoSlidersInnerDiv}`}>
              <h2 className="font-bold">KOLIK MŮŽETE SPOŘIT MĚSÍČNĚ?</h2>
              <Slider
                min={1000}
                step={500}
                max={18000}
                defaultValue={15}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="myAwesomeColor"
                value={value}
                onChange={(e, v) => handleSliderChange(e, v)}
              />
              <div className="pb-4 text-[#34343c]">{`${value.toLocaleString()} Kč`}</div>
            </div>
            <div className={`${twoSlidersInnerDiv}`}>
              <h2 className="font-bold">POČET LET SPOŘENÍ</h2>
              <Slider
                min={1}
                step={1}
                max={7}
                defaultValue={3}
                aria-label="Default"
                valueLabelDisplay="auto"
                color="myAwesomeColor"
                value={value2}
                onChange={(e2, v2) => handleSliderChange2(e2, v2)}
              />
              <div className="pb-4 text-[#34343c]">{`Počet let: ${value2}`}</div>
            </div>
          </div>
          <h2 className="lg:rounded-lg rounded-full p-2 bg-[#68b42c] lg:w-[50%] m-auto text-white font-bold  text-lg lg:text-2xl">{`Očekávaný zůstatek: ${displayValue}`}</h2>
          <div className="lg:flex justify-center items-center lg:divide-x lg:px-4 divide-[#cd9f2d]">
            <h2 className="lg:p-2 text-sm lg:text-lg font-semibold">
              NASPOŘÍTE:{" "}
              <span className="text-[#68b42c]">{` ${totalBeforeIncrease}`}</span>
            </h2>
            <h2 className="lg:p-2 text-sm lg:text-lg font-semibold">
              PŘEDPOKLÁDANÉ ZHODNOCENÍ:{" "}
              <span className="text-[#68b42c]">{` + ${profit}`}</span>
            </h2>
          </div>

          {/* <h2 className="">{`Očekávaný zůstatek + měsíční poplatek: ${moneyWithSubscription}`}</h2> */}
          {/* <h2 className="">
              Aktuální Cena Zlata: 1976,45 $ / 43 304,46 Kč
            </h2> */}
          <button
            className="pt-4 rounded-full text-black outline-1 outline-red-300"
            onClick={resetValue}
          >
            Začít Znovu
          </button>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default Calccom;
